/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./header.scss";
import "./mobile.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faSignOutAlt,
  faCheck,
  faTimes,
  faKey,
  faHome,
  faHeart,
  faBars,
  faBell
} from "@fortawesome/free-solid-svg-icons";
import UrlCollect from "../../common/url-collect";
import ViLanguage from "../../languages/vi";
import {
  DomainUserSide,
  DomainAdminSide,
  APIUrlDefault,
  TokenKey,
  getUserInfo,
  removeCookies,
  setCookies,
  getCookies,
} from "../../utils/configuration";
//import * as statementActions from "../../redux/store/statement/statement.store";

import * as appActions from "../../core/app.store";
import * as acountAction from "../../redux/store/account/account.store";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import {
  NotificationMessageType,
} from "../../utils/configuration";
import { ModalAuthen } from "../auth/modalAuthen";
import Collapse from 'react-bootstrap/Collapse';
import CloseButton from 'react-bootstrap/CloseButton';

const LanguageCollect = ViLanguage;

function RenderModalComponent(props) {
  const { infoAcount, isShowUserInfoModal, handleCloseUserInfoModal, onLogout, showLoading } = props;
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const { register, handleSubmit, errors, getValues, watch, clearErrors, setError } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangePassword = () => {
    const { password, confirmPassword } = getValues();
    password === confirmPassword ? clearErrors(["confirmPassword"]) : setError("confirmPassword", { type: "validate" })
  }

  const onSubmit = (data) => {
    clearErrors();
    if (!data.oldPassword && !data.password && !file) { isShowChangePassword && setIsShowChangePassword(!isShowChangePassword); handleCloseUserInfoModal(); return };
    if (data.oldPassword && !data.password) {
      setError("password", { type: "validate" });
      return;
    }
    if (data.password && !data.oldPassword) {
      setError("oldPassword", { type: "validate" });
      return;
    }
    showLoading(true);
    const value = {
      id: infoAcount.id,
      currentPassword: data.oldPassword,
      newPassword: data.password,
      file: avatar
    }
    acountAction.UpdateUserAccount(value).then((res) => {
      if (res && res.content) {
        if (data.oldPassword && data.password) {
          ShowNotification(
            viVN.Success.ChangePasswordSuccess,
            NotificationMessageType.Success
          );
        } else {
          ShowNotification(
            viVN.Success.ChangeAvatarSuccess,
            NotificationMessageType.Success
          )
        }
      }
      isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
      handleCloseUserInfoModal();
      showLoading(false);
    }).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error)
    })
  }
  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setAvatar(event.target.files[0]);
    }
  }
  const handleOnCloseModal = () => {
    isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
    handleCloseUserInfoModal();
  }
  useEffect(() => {
    setFile(null);
  }, [isShowUserInfoModal])
  return (
    <Modal
      show={isShowUserInfoModal}
      onHide={handleOnCloseModal}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      className="modal-user-info"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Thông tin người dùng</h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-4 text-center">
              {!file ? <img
                src={(infoAcount.avatar || infoAcount.avatar !== "null") ? APIUrlDefault + infoAcount.avatar : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              /> : <img
                src={file ? file : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              />
              }
              <div className="position-relative">
                <button className="btn btn-change-avatar w-75 border mt-4 text-uppercase font-weight-bold">
                  Thay ảnh
                </button>

                <input
                  type="file"
                  name="mediaFile"
                  accept=".png, .jpg, .jpeg"
                  className="media-file"
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
            <div className="col-8">
              <h5 className="text-uppercase">
                <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                Thông tin chung
              </h5>
              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Tài khoản</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Họ và tên</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.fullName}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <h5 className="text-uppercase cursor-pointer" onClick={() => setIsShowChangePassword(!isShowChangePassword)}>
                <FontAwesomeIcon icon={faKey} size="sm" className="mr-2" />
                Đổi mật khẩu
              </h5>
              {isShowChangePassword && (<div>
                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu cũ</label>
                  <div className="col-9">
                    <input type="password" name="oldPassword" onChange={() => clearErrors(["oldPassword"])} placeholder="Nhập mật khẩu" ref={register} className="form-control" />
                    {errors.oldPassword && errors.oldPassword.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu mới</label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                      })}
                      onChange={onChangePassword}
                      name="password"
                      className="form-control"
                      placeholder="Nhập mật khẩu mới"
                    />
                    {errors.password && errors.password.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.password && errors.password.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <span className="error">
                        Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">
                    Nhập lại mật khẩu mới
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === password.current || "Mật khẩu không trùng khớp",
                      })}
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "validate" && (
                        <span className="error">Mật khẩu không khớp</span>
                      )}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleOnCloseModal} variant="secondary" >
            <FontAwesomeIcon icon={faTimes} size="sm" className="mr-2" />
            Hủy
          </Button>
          <Button type="submit" variant="primary" >
            <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      planningType: [],
      isShowUserInfoModal: false,
      infoAcount: null,
      showModalAuthen: false,
      showTabLogin: false,
      showTabRegister: false,
      windowWidth: null,
      openMenu: false,
      isShowFormLogin: false,
      listLookupDistrict: [],
      open: false,
      tcqh: null,
      sub_tcqh: null,
      isAdmin: true,
    };
  }

  componentWillMount() {
    //this.getAllPlanningType();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.isShowFormLogin !== this.state.isShowFormLogin) {
      if (nextProps.isShowFormLogin) {
        ShowNotification(
          viVN.Warning.NeedToLoginToAccess,
          NotificationMessageType.Warning
        );
        this.setState({ isShowFormLogin: nextProps.isShowFormLogin }, () => {
          this.handleOpenLogin();
        })
      }
    }
    if (nextProps.isShowFormLogin !== this.state.isShowFormLogin) {
      if (nextProps.isShowFormLoginQLHS) {
        ShowNotification(
          viVN.Warning.NeedToLoginAdminToAccess,
          NotificationMessageType.Warning
        );
        this.setState({ isShowFormLogin: nextProps.isShowFormLogin }, () => {
          this.handleOpenLogin();
        })
      } else {
        if (nextProps.isAdmin !== this.state.isAdmin) {
          if (this.props.isRedirectHome) {
            ShowNotification(
              viVN.Warning.NeedToLoginAdminToAccess,
              NotificationMessageType.Warning
            );
          }
        }
      }
    }
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  handleOutsideClick = () => {
    const className = document.getElementById("navbarNav").className
    if (this.state.openMenu &&
      className.includes('show') &&
      !this.state.tcqh?.classList.contains('show')
      && !this.state.sub_tcqh?.classList.contains('show')) {
      this.setState({ openMenu: false })
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    //window.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    //window.removeEventListener('click', this.handleOutsideClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      this.setState({
        isLogin: this.props.isLoggedIn
      })
    }
  }

  getUserAccountDetail() {
    this.props.showLoading(true);
    acountAction.GetUserAccountDetail().then((res) => {
      this.setState({ infoAcount: res && res.content ? res.content : null })
      this.props.showLoading(false);
    }).catch((err) => {
      this.props.showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error
      );
      console.log("err", err);

    });
  }




  onLogout() {
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    setCookies(TokenKey.returnUrl, window.location.href)
    window.location.replace(DomainUserSide);
    setCookies("SHOW_FORM_LOGIN", true);
    setCookies("IS_ADMIN", false);
    this.props.logout();
  }

  //--- User info modal
  handleOpenUserInfoModal = () => {
    this.getUserAccountDetail();
    this.setState({
      isShowUserInfoModal: true,
    });
  };

  handleCloseUserInfoModal = () => {
    this.setState({
      isShowUserInfoModal: false,
    });
  };

  onToggleModalAuthen = () => {
    this.setState({ showModalAuthen: !this.state.showModalAuthen }, () => {
      if (!this.state.showModalAuthen) {
        this.props.setIsCancelLogin && this.props.setIsCancelLogin(true);
      }
    })
  }

  toggleSubMenuDistrict = (data) => {
    if (data?.classList.contains('show')) {
      this.state.tcqh.classList.add("show");
      data.classList.remove("show");
    } else {
      data.classList.add("show");
    }
  }

  handleToggleSubMenuDistrict = () => {
    this.toggleSubMenuDistrict(this.state.sub_tcqh);
  }

  handleOpenRegister = () => {
    this.onToggleModalAuthen();
    this.setState({ showTabRegister: true, showTabLogin: false });
  }
  handleOpenLogin = () => {
    this.onToggleModalAuthen();
    this.setState({ showTabLogin: true, showTabRegister: false });
  }

  onClickTraCuuQuyHoach = () => {
    var tcqh = document.getElementById("TraCuuQuyHoach");
    var sub_tcqh = document.getElementById("SubTraCuuQuyHoach");
    this.setState({ tcqh, sub_tcqh });
  }

  render() {
    const PATHNAME = this.props.match.path;
    const { showModalAuthen, showTabLogin, showTabRegister, isLogin } = this.state;

    return (
      <header className="header">
        <div className="top-banner">
          CẢNH BÁO LŨ LỤT - THÀNH PHỐ HUẾ, TỈNH THỪA THIÊN HUẾ
        </div>
        <div className="main-header">
          <div className="container">
            <div className="header-content">
              <div className="logo-section">
                <img src={require('../../assets/image/logo.png')} alt="Logo" />
                <div className="title">
                  <h1>CỔNG THÔNG TIN</h1>
                  <p>Hệ thống giám sát và cảnh báo lũ lụt</p>
                </div>
              </div>
              <nav className="nav-menu">
                <Link to="/" className={`nav-link ${PATHNAME === "/" ? "active" : ""}`}>
                  {LanguageCollect.header_homePage}
                </Link>
                <Link to="/ban-do/57" className={`nav-link ${PATHNAME.includes("/ban-do") || PATHNAME === "/tong-hop-quy-hoach" ? "active" : ""}`}>
                  {LanguageCollect.planningMapView_map}
                </Link>
                <Link to="/tin-tuc" className={`nav-link ${PATHNAME === "/tin-tuc" ? "active" : ""}`}>
                  {LanguageCollect.header_news}
                </Link>
                <Link to="/lien-he" className={`nav-link ${PATHNAME === "/lien-he" ? "active" : ""}`}>
                  {LanguageCollect.header_Contact}
                </Link>
              </nav>
              <div className="action-buttons">
                <button className="icon-button">
                  <FontAwesomeIcon icon={faBell} />
                </button>
                <div className="dropdown">
                  <button className="icon-button" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FontAwesomeIcon icon={faUser} />
                  </button>
                  <div className="dropdown-menu" aria-labelledby="userDropdown">
                        <a className="dropdown-item" href={DomainAdminSide+"/dang-nhap"}>
                          <FontAwesomeIcon icon={faLock} className="mr-2" />
                          {LanguageCollect.header_administration}
                        </a>
      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.infoAcount && (
          <RenderModalComponent 
            infoAcount={this.state.infoAcount} 
            showLoading={this.props.showLoading} 
            onLogout={this.onLogout} 
            isShowUserInfoModal={this.state.isShowUserInfoModal} 
            handleCloseUserInfoModal={this.handleCloseUserInfoModal} 
          />
        )}
        <ModalAuthen
          show={showModalAuthen}
          onHide={this.onToggleModalAuthen}
          showTabRegister={showTabRegister}
          showTabLogin={showTabLogin}
          returnUrl={this.props.returnUrl}
        />
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      //getAllPlanningType: null,
      logout: acountAction.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);

import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";

function SearchTab(props) {
    const handleOpenModalLandSlides = () => {
        props.setShowNote(false)
        props.setShowLandSlides(true)
      }
      const handleOpenModalFloods = () => {
        props.setShowNote(true)
        props.setShowLandSlides(false)
      }
    return (
        <div id="mapSearchContainer" style={{margin:5}}>
              <Button onClick={handleOpenModalLandSlides} variant="secondary" >
                Tìm kiếm điểm sạt lở
              </Button>
              <br/>
                        <Button onClick={handleOpenModalFloods} variant="secondary" style={{marginTop:5}}>
                          Tìm kiếm điểm lũ lụt
                        </Button>
        </div>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            savePreviewImage: MapDataStore.SavePreviewImage,
        }, dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SearchTab);
import React, { Fragment } from "react";
import "./planning-map-view.scss";
import ViLanguages from "../../languages/vi";
 import TopRightPanelView from "./top-right-panel/top-right-panel.view";
import LeftMenuView from "./left-menu/left-menu.view";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import * as MapToolDataStore from "../../redux/store/map-tool/map-tool.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import {
  ExtractSelectedBasemap,
  ExtractListLayerArray,
  ExtracLayerListGroup,
  ExtractBasemapList,
} from "../../common/extract-information-from-map-data";

const LanguageCollects = ViLanguages;

class PlanningMapView extends React.Component<
  PlanningMapViewModel.PlanningMapViewProps,
  PlanningMapViewModel.PlanningMapViewState
> {
  constructor(props: PlanningMapViewModel.PlanningMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: null,
      isLeftNavbarHide: true,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null,
      coordinate: "",
      isDoneConstructMap: false,
      projectcoordinate: null,
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup =
      this.closeFilterInfomationPopup.bind(this);
  }

  setIsDoneConstructMap(data: boolean) {
    this.setState ({
      isDoneConstructMap: data
    })
  }

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetMapDetail(this.props.match.params.id);
  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement && buttonElement.classList.contains("collapsed"))
      buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };
  setPlanningMap = (_planningModel: any) => {
    this.setState({ planningModel: _planningModel });
  };

  componentDidUpdate(prevProps: Readonly<PlanningMapViewModel.PlanningMapViewProps>, prevState: Readonly<PlanningMapViewModel.PlanningMapViewState>, snapshot?: any): void {
    if( this.props.mapData != prevProps.mapData) {
      this.props.SetDataToggleLayer(ExtracLayerListGroup(this.props.mapData));
    }

    if( this.state.isDoneConstructMap != prevState.isDoneConstructMap) {
      this.setState({isLeftNavbarHide: false});
    }
  }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;
    return (
      <div className="planning-map-view-container position-relative">
        {/* top right panel*/}
        <TopRightPanelView
          baseMapList={ExtractBasemapList(this.props.mapData)}
        />

        {this.props.hasLoadedMapData && this.props.mapData.name && (
        <div>
          <OpenlayersMapView
            defaultBaseMapUrl={ExtractSelectedBasemap(this.props.mapData)}
            defaultCenter={[107.68040723520843,16.410640668484156]}
            defaultZoom={10}
            projection={"EPSG:4326"}
            maxZoom={24}
            minZoom={2}
            extent={[107.0525777175843,16.06075651022487,108.30823675283257,16.760524826743442]}
            projectcoordinate={this.state.projectcoordinate }
            listLayer={ExtractListLayerArray(this.props.mapData)}
            planningModel={this.state.planningModel}
            listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
            coordinate={this.props.location}
            setIsDoneConstructMap={(data) => this.setIsDoneConstructMap(data)}
          />
        </div>
        )}


        <div>
          {this.props.hasLoadedMapData && this.props.mapData.name && (
            <Fragment>
              <LeftMenuView
                planningId={this.props.match.params.id}
                isLeftNavbarHide={isLeftNavbarHide}
                setProjectcoordinate={(data: any) => this.setState({ projectcoordinate: data })}
                toggleStateIsLeftNavbarHide={() =>
                  this.setState({
                    isLeftNavbarHide: !this.state.isLeftNavbarHide,
                  })
                }
                openFilterInfomationPopup ={() => this.openFilterInfomationPopup()}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      SetDataToggleLayer: MapToolDataStore.SetDataToggleLayer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanningMapView);

import { Dashboard } from "@material-ui/icons";

const UrlCollect = {
  Home: '/',
  Login: '/dang-nhap',
  SpecializedPlanning: '/quy-hoach-chuyen-nganh',
  SyntheticPlanning: '/tong-hop-quy-hoach',
  GeneralPlanning: '/quy-hoach-chung',
  SubdivisionPlanning: '/quy-hoach-phan-khu',
  DetailedPlanning: '/quy-hoach-chi-tiet',
  PlanningAnnouncement: '/cong-bo-quy-hoach',
  News: '/news',
  RecordsManager: '/quan-ly-ho-so',
  Contact: '/lien-he',
  PlanningMapViewDetail: '/ban-do/',
  PlanningMap: '/ban-do-quy-hoach',

  //-- User
  ConfirmCode: '/xac-nhan',
  ResetPassword: '/dat-lai-mat-khau',
  UserInfo: '/thong-tin-ca-nhan',

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: '/y-kien-cong-dong',
  PleaseComment: '/xin-y-kien',
  SummaryIdeas: '/y-kien-tong-hop',

  //-- Tin tuc
  Dashboard: '/dashboard',
  NewsList: '/tin-tuc',
  NewsDetail: '/tin-tuc-chi-tiet',
  NewsListQHT: '/tin-tuc-quy-hoach-tinh',
  NewsListQHHTKT: '/tin-tuc-noi-dung-qhhtkt',
  NewsListQHCC: '/tin-tuc-quy-hoach-cac-cap',
  NewsDetailQHT: '/tin-tuc-quy-hoach-tinh/chi-tiet',
  NewsDetailQHHTKT: '/tin-tuc-noi-dung-qhhtkt/chi-tiet',
  NewsDetailQHCC: '/tin-tuc-quy-hoach-cac-cap/chi-tiet',

  //-- Error
  Page500: '/page-500/',

  //-- Admin
  ProcessBoardPlanning: '/quy-trinh-cong-bo-quy-hoach',

  //-- Policy
  Policy: '/policy',
};

export default UrlCollect;

import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();


export const GetListFloods = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  params.append("placename", data.placename);
  params.append("surveyer", data.surveyer);
  params.append("surveyerDate", data.surveyerDate);
  return service
      .get(ApiUrl.GetListFloods, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};
export const GetListLandSlide = (data) => {
    const params = new URLSearchParams();
    params.append("pageIndex", data.pageIndex);
    params.append("pageSize", data.pageSize);
    params.append("placename", data.placename);
    params.append("surveyer", data.surveyer);
    params.append("surveyerDate", data.surveyerDate);
  
    return service
        .get(ApiUrl.GetListLandSlide, params)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          throw err;
        });
  };
// export const GetListFloods = (data) => {
//   const params = new URLSearchParams();
//   params.append("pageIndex", data.pageIndex);
//   params.append("pageSize", data.pageSize);

//   data.title && params.append("title", data.title);

//   return service
//       .get(ApiUrl.GetListFloods, params)
//       .then((res) => {
//         return res;
//       })
//       .catch((err) => {
//         throw err;
//       });
// };

// export const GetListLandSlide = (data) => {
//     const params = new URLSearchParams();
//     params.append("pageIndex", data.pageIndex);
//     params.append("pageSize", data.pageSize);
  
//     data.title && params.append("title", data.title);
  
//     return service
//         .get(ApiUrl.GetListLandSlide, params)
//         .then((res) => {
//           return res;
//         })
//         .catch((err) => {
//           throw err;
//         });
//   };
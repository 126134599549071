/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./openlayers-map.scss";
import OpenLayerComponent from "../../models/open-layer/props-state";
import { connect } from "react-redux";
import * as OpenlayerStore from "../../redux/store/open-layer/open-layer.store";
import * as homePageStore from "../../redux/store/home-page/home-page.store";
import history from "../../common/history";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import Jquery from "jquery";
import { ConvertColsDataDetailViewToStandardData } from "./config/convert-data";
import UrlCollect from "../../common/url-collect";
import { geolocated } from "react-geolocated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import * as LoadingAction from '../../redux/store/loading/loading.store';
//@ts-ignore
import domtoimage from 'dom-to-image-more';
//--- Config
import {
  VectorImageLayerClassName,
  TileLayerClassName,
  Default_Feature_Style,
  Highlight_Feature_Style,
  Draw_Filter_Feature_Style,
} from './config/config';

//--- Store
import * as MapToolsPanelsStore from "../../redux/store/open-layer/map-tools-panels.store";
import * as mapHelper from "../../common/map";

//--- Openlayer
import TileWMS from 'ol/source/TileWMS';
import VectorImageLayer from "ol/layer/VectorImage";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import MousePosition from "ol/control/MousePosition";
import { createStringXY, toStringHDMS } from "ol/coordinate";
import { Image as ImageLayer } from "ol/layer";
import ImageWMS from "ol/source/ImageWMS";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import WKT from "ol/format/WKT";
import Layer from "ol/layer/Layer";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import { getArea, getLength } from "ol/sphere";
import Draw, { createBox } from "ol/interaction/Draw";
import { unByKey } from "ol/Observable";
import GeoJSON from "ol/format/GeoJSON";
import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";
import DragBox from "ol/interaction/DragBox";
import { toLonLat, transform } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import Projection from 'ol/proj/Projection';
import OlOverlay from 'ol/Overlay';

//--- Models
import { BoundariesViewModel,RelatedLayerModel } from "../../models/map-data-model-b/new-planning-relation.model";
import LayerSettingsModels from "../../models/map-data-model-b/layer-setting-models";
import SingleMapInfoMationModel from "../../models/map-data-model-b/single-map-infomation-model";
import ResponeFromServeClickShowInfomationFeature from "../../models/open-layer/respone-get-future-info-url-ajax";
import ImageLayerModels from "../../models/open-layer/image-layer";
import BoundariesModel from "../../models/map-data-model-b/new-boundaries.model";
import * as config from "../../utils/configuration";
import GetAllPlanningBoundariesMode from "../../models/map-data-model-b/get-all-planning-boundaries.model";
import DrawnFilterDataModel from '../../models/open-layer/drawn-filter-data.model';
import MapContainer from './childs/map-container.view';
import Notification from '../../common/notification-service';

import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import * as mapToolAction from "../../redux/store/map-tool/map-tool.store";
import GeometryType from "ol/geom/GeometryType";
import { stringToGlsl } from "ol/style/expressions";

import * as viLanguage from "../../languages/vi";
import { Popover } from "react-bootstrap";

var selected: any = null;
var sketchGolbal: Feature | null;
var helpTooltipElementGolbal: HTMLElement;
var helpTooltipGolbal: Overlay;
var measureTooltipElementGolbal: HTMLElement | null;
var measureTooltipGolbal: Overlay;
var drawMeasureGolbal: any;
var selectClick = new Select({
  condition: click,
  style: Highlight_Feature_Style
});
var select: any = null; // ref to currently selected interaction
const classNameVectorLayerFilterDrawn = 'dawn-filter-vector-layer';
const DrawnFilterData: DrawnFilterDataModel = {
  draw: null,
  source: null,
  filterType: '',
}
const animateDurationZoomedOut: number = 1000;
const animateDurationZoomedIn: number = 1000;
var query: any = null;
class OpenlayersMapView extends React.Component<
  OpenLayerComponent.PropsType,
  OpenLayerComponent.StateType
  > {
  constructor(props: OpenLayerComponent.PropsType) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
      currentLayer: null,
      currentLayerSettingModel: null,
      useFeatureViewInfomationOnClick: false,
      modals: false,
      coordinate: this.props.coordinate,
      listLayerSettingModel: [],
      primaryIdLayer: 0,
      isLoadedAllVectorSource: false,
      currentZoom:0,
      prevFeatureId: "",
      prevFeatureChildId: [],
    };
  }

  toggleDisplayVectorLayer = (
    boundaries: BoundariesModel,
    isDisplay: boolean,
    minZoom: number = 1,
    maxZoom: number = 20,
    tooltipValue: string = "",
    planningId: number = 0
  ) => {
    const LayersCurrents = this.state.map?.getLayers();
    let isBoundariesAdded = false;
    LayersCurrents?.forEach((layerModel: Layer) => {
      if (layerModel.getClassName() === `id-layer-${boundaries.nameTb}`) {
        isBoundariesAdded = true;
        layerModel.setVisible(isDisplay);
        return;
      }
    });
    if (!isBoundariesAdded) {
      const format = new WKT();

      const feature: any[] = [];
      const featureObject = format.readFeature(boundaries.geomText, {
        dataProjection: this.props.projection,
        featureProjection: this.props.projection,
      });
      featureObject.setProperties({ tooltipValue: tooltipValue });
      featureObject.setProperties({ planningId: planningId });
      feature.push(featureObject);

      LayersCurrents?.push(
        new VectorLayer({
          className: `id-layer-${boundaries.nameTb}`,
          minZoom: minZoom,
          maxZoom: maxZoom,
          source: new VectorSource({
            features: [...feature],
          }),
        })
      );
    }
  };

  changeBaseMap = (newBasemapUrl: string | undefined) => {
    const LayersCurrents = this.state.map?.getLayers();
    if (newBasemapUrl) {
      LayersCurrents?.insertAt(
        0,
        new TileLayer({
          source: new OSM({
            url: newBasemapUrl,
            maxZoom: this.props.maxZoom,
          }),
        })
      );
      LayersCurrents?.removeAt(1);
    } else {
      const BaseMapLayer = LayersCurrents?.item(0).setVisible(false);
    }
  };


  toggleDisplayLayer = (
    layer: LayerSettingsModels.LayerSettingsModel,
    opacity?: number
  ) => {
    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach(
      (layerElement: ImageLayerModels.ImageLayerModel) => {
        const ParamsProperty = layerElement.getProperties().source.params_;
        const layerClassName = layerElement.className_;
        if(layer.geoTiff && layerClassName.includes(layer.geoTiff)) {
          layerElement.setVisible(!layerElement.getVisible());
        }
        if (ParamsProperty && ParamsProperty.LayerId === layer.id) {
          layerElement.setVisible(layer.is_check);
        }
        if (layerClassName === VectorImageLayerClassName(layer.id)) {
          layerElement.setVisible(layer.is_check);
          // if (opacity) {
          //   layerElement.setOpacity(opacity);
          // }
        }
        if (opacity) {
          if (ParamsProperty && ParamsProperty.LayerId !== layer.id) {
            layerElement.setOpacity(opacity);
          }
        }
      }
    );
  };

  handleSelectOne = () => {
    this.handleOnOffViewInfomation(true);
  };

  removeInteractionOnMap = () => {
    let interactionArr = this.state.map?.getInteractions().array_;
    let length = interactionArr.length;
    if (interactionArr && length === 0) return;
    interactionArr.map((item: any, index: number) => {
      if (item instanceof DragBox) {
        this.state.map?.getInteractions().array_.splice(index, 1);
      }
    })
  }

  handleOnOffViewInfomation = (isTurnOn: boolean) => {
    // this.removeHiglightVectorLayer();
    this.deleteHightlightFilterLayer();
    const mapObject: Map = this.state.map;
    //đoạn check this.props.isGeneralPlanning cho vào hàm functionClickViewInfomationListener
    if (isTurnOn) {
      mapObject.on("singleclick", this.functionClickViewInfomationListener);
    } else {
      mapObject.un("singleclick", this.functionClickViewInfomationListener);
    }
    //this.changeInteraction();
  };
  
  //--- core function

  functionTurnOffFilterMode = () => {
    const filterButtonElement = document.querySelector(
      '[data-icon-name="filter"]'
    );
    if (filterButtonElement) {
      if (filterButtonElement.classList.contains("active")) {
        const informationTool = (document.querySelector(".tool-button") as HTMLElement);
        informationTool?.click();
      }
    }
    
  }

  functionRemoveAllMapOverlay = () => {
    const CollectsOfOverlay = this.state.map.getOverlays();
    CollectsOfOverlay.clear();
  };

  functionAddInteraction_MeasureMode = (
    isLineMode: boolean,
    vectorSource: VectorSource
  ) => {
    let type: any = isLineMode ? "LineString" : "Polygon";
    drawMeasureGolbal = new Draw({
      source: vectorSource,
      type: type,
      style: new Style({
        fill: new Fill({
          color: "rgba(234, 255, 0,0.5)",
        }),
        stroke: new Stroke({
          color: "rgba(255, 0, 0, 0.8);",
          // lineDash: [10, 10],
          width: 3,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(255,0,0,0.3);",
          }),
          fill: new Fill({
            color: "rgba(29, 32, 232,1)",
          }),
        }),
      }),
    });
    this.state.map?.addInteraction(drawMeasureGolbal);

    this.functionCreateMeasureTooltip_MeasureMode();
    this.functionCreateHelpTooltip_MeasureMode();

    let listener: any;
    const self = this;
    drawMeasureGolbal.on("drawstart", (evt: any) => {
      sketchGolbal = evt.feature;

      let tooltipCoord = evt.coordinate;

      listener = sketchGolbal?.getGeometry().on("change", function (evt) {
        const geom = evt.target;
        let output;
        if (geom instanceof Polygon) {
          output = self.functionFormatPolygon_MeasureMode(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = self.functionFormatLength_MeasureMode(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        if (measureTooltipElementGolbal)
          measureTooltipElementGolbal.innerHTML = output || "";
        measureTooltipGolbal.setPosition(tooltipCoord);
      });
    });

    drawMeasureGolbal.on("drawend", function () {
      if (measureTooltipElementGolbal)
        measureTooltipElementGolbal.className = "ol-tooltip ol-tooltip-static";
      measureTooltipGolbal.setOffset([0, -7]);
      sketchGolbal = null;
      measureTooltipElementGolbal = null;
      self.functionCreateMeasureTooltip_MeasureMode();
      unByKey(listener);
    });
  };

  functionCreateHelpTooltip_MeasureMode = () => {
    helpTooltipElementGolbal?.parentNode?.removeChild(helpTooltipElementGolbal);
    helpTooltipElementGolbal = document.createElement("div");
    helpTooltipElementGolbal.className = "ol-tooltip hidden";
    const positioning: any = "center-left";
    helpTooltipGolbal = new Overlay({
      element: helpTooltipElementGolbal,
      offset: [15, 0],
      positioning: positioning,
    });
    this.state.map.addOverlay(helpTooltipGolbal);
  };

  functionCreateMeasureTooltip_MeasureMode = () => {
    measureTooltipElementGolbal?.parentNode?.removeChild(
      measureTooltipElementGolbal
    );
    measureTooltipElementGolbal = document.createElement("div");
    measureTooltipElementGolbal.className = "ol-tooltip ol-tooltip-measure";
    const positioning: any = "bottom-center";
    measureTooltipGolbal = new Overlay({
      element: measureTooltipElementGolbal,
      offset: [0, -15],
      positioning: positioning,
    });
    this.state.map.addOverlay(measureTooltipGolbal);
  };

  functionFormatLength_MeasureMode = (line: LineString): string => {
    const length = getLength(line, {
      projection: this.props.projection,
    });
    let result = "";
    if (length > 100) {
      result = Math.round((length / 1000) * 100) / 100 + " " + "km";
    } else {
      result = Math.round(length * 100) / 100 + " " + "m";
    }

    return result;
  };

  functionFormatPolygon_MeasureMode = (polygon: Polygon): string => {
    const area = getArea(polygon, {
      projection: this.props.projection,
    });
    let result = "";
    //To ha

    result = Math.round((area / 10000) * 100) / 100 + " " + "ha";

    //to km2
    // if (area > 10000) {
    //   result =
    //     Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
    // } else {
    //   result = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
    // }
    return result;
  };

  functionMouseOut_MeasureMode = () => {
    helpTooltipElementGolbal?.classList.add("hidden");
  };

  functionPointerMoveHandle_MeasureMode = (event: MapBrowserEvent) => {
    if (event.dragging) return;
    if (sketchGolbal) {
      const geom = sketchGolbal.getGeometry();
      if (geom instanceof Polygon) {
      } else if (geom instanceof LineString) {
      }
    }
    if (helpTooltipElementGolbal) {
      helpTooltipGolbal.setPosition(event.coordinate);

      helpTooltipElementGolbal.classList.remove("hidden");
    }
  };

  functionClickPinMarker_PinMarkerMode = (event: MapBrowserEvent) => {
    const LayersCurrents = this.state.map?.getLayers();
    LayersCurrents?.forEach((layerObject: VectorLayer) => {
      if (layerObject.getClassName() === "pin-marker-vector-layer") {
        const newMarker = new Feature({
          type: "icon",
          geometry: new Point(event.coordinate),
        });
        layerObject.getSource().addFeature(newMarker);
        return;
      }
    });
  };

  checkPinMarker = () => {
    let currentLayers = this.state.map?.getLayers();
    currentLayers?.forEach((LayerObject: VectorLayer) => {
      if (LayerObject.getClassName() === "pin-marker-vector-layer") {
        return true;
      }
    });
    return false;
  };

  setHeightViewport = () => {
    // const HeaderContainerHeight = (document.getElementById("header-map-page-container") as HTMLElement).offsetHeight 
    //   + (document.getElementById("header_banner") as HTMLElement).offsetHeight;
    this.setState(
      {
        mapContainerStyle: {
          height: `${window.innerHeight - 30}px`,
        },
      },
      () => {
        this.state.map?.updateSize();
      }
    );
  };

  functionHandleClickLayerToDirectDetail = (pixel: any) => {
    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().planningId
    ) {
      window.open(
        UrlCollect.PlanningMapViewDetail + feature.getProperties().planningId
      );
    }
  };

  functionHoverHightlightAndShowTooltip = (pixel: any) => {
    const tooltipContainer = document.getElementById(
      "tooltip-container-openlayer"
    ) as HTMLElement;
    let left = pixel[0] + 20;
    let top = pixel[1] + 20;
    if (tooltipContainer) {
      tooltipContainer.style.left = left + "px";
      tooltipContainer.style.top = top + "px";
      tooltipContainer.style.opacity = "1";
    }

    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().tooltipValue
    ) {
      tooltipContainer.innerHTML = feature.getProperties().tooltipValue;
    } else {
      tooltipContainer.style.opacity = "0";
    }
  };

  functionHoverHightlightAndShowPopUp = (pixel: any) => {
    const popupContainer = document.getElementById(
      "popup-container-openlayer"
    ) as HTMLElement;
    if (popupContainer) {
      popupContainer.style.right = 50 + "px";
      popupContainer.style.bottom = 50 + "px";
      popupContainer.style.opacity = "1";
      popupContainer.style.display = "block";
    }

    const feature = this.state.map?.forEachFeatureAtPixel(pixel, function (
      feature: any
    ) {
      return feature;
    });
    if (
      feature &&
      feature.getProperties() &&
      feature.getProperties().tooltipValue &&
      feature.getProperties().tooltipValue &&
      feature.getProperties().acreage &&
      feature.getProperties().population
    ) {
      popupContainer.innerHTML = `<div>
            <span id="title"> ${feature.getProperties().tooltipValue || "--"
        }</span><br/>
            <span id="title">Diện tích:</span> ${feature.getProperties().acreage || "--"
        }<br/>
            <span id="title">Dân số:</span>${feature.getProperties().population || "--"
        } <br />
            <span id="title">Quyết định phê duyệt:</span> ${feature.getProperties().report || "--"
        } <br />
            </div>
            `;
    } else {
      popupContainer.style.opacity = "0";
      popupContainer.style.display = "none";
    }
  };

  functionClickShowPopUpInClickedCord = (featureInfo : any, coordinate: any) => {
    if(!this.props.isGeneralPlanning) return;
    const mapObject: Map = this.state.map;
    const popup = mapObject.getOverlayById("show-popup-clicked-cord");
    if(!popup) return;
    const element : HTMLElement = popup.getElement();

    const temp = element.getElementsByClassName("pop_over_clicked_cord")[0];
    if(temp) element.removeChild(temp);
    
    if (!featureInfo) {
      element.style.display = "none";
    }else {
      popup.setPosition(coordinate);
      var newDiv = document.createElement("div");
      newDiv.className = "pop_over_clicked_cord";
      newDiv.innerHTML = featureInfo?.properties.planning_name;
      newDiv.onclick = function() {
        window.open(UrlCollect.PlanningMapViewDetail + featureInfo?.properties.planning_id, '_blank');
      };
      element.appendChild(newDiv);
      element.style.display = "block";
    }
  }

  functionClickShowPopUp = (featureInfo :any) => {
    const planning_code = featureInfo?.planning_code;
    const popupContainer = document.getElementById(
      "click-show-popup-container-openlayer"
    ) as HTMLElement;
    if (popupContainer) {
      popupContainer.style.right = 50 + "px";
      popupContainer.style.bottom = 50 + "px";
      popupContainer.style.opacity = "1";
      popupContainer.style.display = "block";
    }
    
    if (
      featureInfo
    ) {
      console.log('planning_code',planning_code);
      
      popupContainer.innerHTML = `<div>
            <span id="title"> ${planning_code || "--"
        }</span>
            </div>
            `;
    } else {
      popupContainer.style.opacity = "0";
      popupContainer.style.display = "none";
    }
  };

  functionClickViewInfomationListener = (evt: any) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    const listTitleLayerVisible: TileLayer[] = [];
    const listImageLayerVisible: ImageLayer[] = [];
    const listVectorImageLayerVisible: VectorImageLayer[] = [];
    const view = mapObject.getView();
    const viewResolution = view.getResolution();
    const buttonTabMapinfomation = document.getElementById(
      "ButtonControlMapInfomationTab"
    );
    const mapInfomationContainer = document.getElementById(
      "mapInfomationContainer"
    );
    const planningInfomationSmallPopupElement = (document.getElementById("planning-infomation-small-popup") as HTMLElement);
    let layerIndex = 0;
    console.log('CLick to map and view info');
    
    const getFeaturesWithTitleLayer = (titleLayer: TileLayer | ImageLayer) => {
      // console.log('titleLayer_xxx',titleLayer);
      const source: any = titleLayer.getSource();
      const layerId = source.getParams()['LayerId'];
      const currentLayerSettingModel = this.state.listLayerSettingModel.filter(x => x.id === layerId)[0];

      const url = source.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        view.getProjection(),
        { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
      );
      //console.log("currentLayerSettingModel",currentLayerSettingModel);

      // ---layerchild
      const currentLayerChildSettingModel =
        (currentLayerSettingModel && currentLayerSettingModel.layerRealationships &&
          currentLayerSettingModel.layerRealationships.length > 0) ?
          currentLayerSettingModel.layerRealationships : null;
      // console.log('currentLayerSettingModel', currentLayerChildSettingModel)

      const getInfo = (url: any, currentLayerChildSettingModel: any, numberOfChild: number) => {
          if (url) {
            Jquery.ajax({
              type: "POST",
              url: url,
              contentType: "application/json; charset=utf-8",
              dataType: "json",
              success: (featureCollection: ResponeFromServeClickShowInfomationFeature) => {
                if (featureCollection.features.length > 0) {
                  const _geojsonObject = featureCollection;
                  const ListInfoMation: SingleMapInfoMationModel[] = [];

                  /*
                  * Lay thong tin de hien len bang thuoc tinh ben tay trai
                  * */

                  const featureInfo: any = featureCollection.features[0];

                  if(this.state.prevFeatureChildId.length > numberOfChild && !this.state.prevFeatureChildId.includes(featureInfo.id)) {
                    this.setState({prevFeatureChildId: []});
                    this.removeHiglightVectorLayerChild();
                    this.props.SaveChildInfomationList([]);
                    return;
                  }

                  if( this.state.prevFeatureChildId.includes(featureInfo.id) ) {
                    const temp = [...this.state.prevFeatureChildId];
                    const index = temp.indexOf(featureInfo.id);
                    if (index !== -1) {
                      temp.splice(index, 1);
                    }
                    this.setState({prevFeatureChildId: temp});
                    return;
                  }
                  this.setState({prevFeatureChildId: [...this.state.prevFeatureChildId,featureInfo.id]});
                  
                  const setDataForTabInformation = (featuresProperies: any) => {
                    if (currentLayerChildSettingModel) {
                      const layerSettingModel = currentLayerChildSettingModel;
                      const colsRaw = ConvertColsDataDetailViewToStandardData(
                        layerSettingModel.display_name.cols
                      );
                      const displayDetails = layerSettingModel.display_name.viewdetail;
                      //console.log('displayDetails',displayDetails);
                      if (displayDetails.use) {
                        displayDetails.cols.map((columnName: any) => {
                          ListInfoMation.push({
                            label: colsRaw[columnName],
                            value: featuresProperies[columnName],
                          });
                        });
                        if (layerSettingModel.layerRealationshipId !== 0) {
                          ListInfoMation.push({
                            label: "Năm điều chỉnh",
                            value: `${layerSettingModel.year}`,
                          },
                          {
                            label: "qddc",
                            value: `${layerSettingModel.contentChange}`,
                          }
                          );
                        }

                      }
                    }
                  }
                  setDataForTabInformation(featureInfo?.properties)

                  /********************************************************************/
                  // console.log('dfsfdsgtidfhg', currentLayerChildSettingModel)
                  this.props.SaveChildInfomationList([...this.props.listChildInfomations ,ListInfoMation]);
                  if (!this.state.isLoadedAllVectorSource) {
                    let _isHaveHighlightVectorlayer = false;
                    listLayer.forEach((_layer) => {
                      if ((_layer instanceof VectorLayer) &&
                        (_layer.getClassName() === "highlight-vectorlayer-child")
                      ) {
                        _isHaveHighlightVectorlayer = true;
                        const _newVectorSource = new VectorSource({
                          features: new GeoJSON().readFeatures(_geojsonObject),
                        })
                        _layer.setSource(_newVectorSource)
                      }
                    })
                    // console.log('_isHaveHighlightVectorlayer',_isHaveHighlightVectorlayer);
                    if (!_isHaveHighlightVectorlayer) {
                      // console.log('_geojsonObject', _geojsonObject)
                      listLayer.push(
                        new VectorLayer({
                          className: "highlight-vectorlayer-child",
                          source: new VectorSource({
                            features: new GeoJSON().readFeatures(_geojsonObject),
                          }),
                          zIndex: 999,
                          style: new Style({
                            stroke: new Stroke({
                              color: 'rgba(0,230,241,1)',
                              width: 2,
                            }),
                            fill: new Fill({
                              color: 'rgba(223,16, 188,0.5)',
                            }),
                          }),
                        })
                      )
                    }
                  }
                }
                else {
                  this.removeHiglightVectorLayerChild();
                  layerIndex++;
                  if (layerIndex < listTitleLayerVisible.length) {
                  } else {
                    this.props.SaveChildInfomationList([...this.props.listChildInfomations]);
                  }
                }
              }
            })
            } else {
            this.props.SaveChildInfomationList([...this.props.listChildInfomations]);
          }
      }
      this.props.SaveChildInfomationList([]);

      currentLayerChildSettingModel && currentLayerChildSettingModel.map((item) => {
        listLayer.forEach((layer) => {
          if ((layer instanceof TileLayer) && (layer.getClassName() == `title-layer-${item.id}`)) {
            const source: any = layer.getSource()
            const url = source.getFeatureInfoUrl(
              evt.coordinate,
              viewResolution,
              view.getProjection(),
              { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
            );
            getInfo(url, item, currentLayerChildSettingModel.length);
          }
        })
      })
        

      if (url) {
        Jquery.ajax({
          type: "POST",
          url: url,
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          success: (featureCollection: ResponeFromServeClickShowInfomationFeature) => {
            if (featureCollection.features.length > 0) {
              const _geojsonObject = featureCollection;
              const ListInfoMation: SingleMapInfoMationModel[] = [];

              /*
              * Lay thong tin de hien len bang thuoc tinh ben tay trai
              * */

              const featureInfo: any = featureCollection.features[0];

              if( featureInfo.id == this.state.prevFeatureId) {
                //tắt cha
                this.props.SaveInfomationList([]);
                this.setState({prevFeatureId: ""});
                this.removeHiglightVectorLayer();

                //tắt con
                this.props.SaveChildInfomationList([]);
                this.removeHiglightVectorLayerChild();
                return;
              }

              this.setState({prevFeatureId: featureInfo.id});
              
              if (this.props.isGeneralPlanning) {
                this.functionClickShowPopUp(featureInfo?.properties);
                // this.functionHoverHightlightAndShowTooltip(this.state.map.getEventPixel(evt.originalEvent));
                // window.open(UrlCollect.PlanningMapViewDetail + featureInfo?.planning_id, '_blank');
                this.functionClickShowPopUpInClickedCord(featureInfo, evt.coordinate);
                // console.log(mapObject.getOverlays());

              }

              const setDataForTabInformation = (featuresProperies: any) => {
                if (currentLayerSettingModel) {
                  const layerSettingModel = currentLayerSettingModel;
                  const colsRaw = ConvertColsDataDetailViewToStandardData(
                    layerSettingModel.display_name.cols
                  );
                  const displayDetails = layerSettingModel.display_name.viewdetail;
                  //console.log('displayDetails',displayDetails);
                  if (displayDetails.use) {
                    displayDetails.cols.map((columnName) => {
                      ListInfoMation.push({
                        label: colsRaw[columnName],
                        value: featuresProperies[columnName],
                      });
                    });
                    if (currentLayerSettingModel.layerRealationships &&
                      currentLayerSettingModel.layerRealationships.length > 0) {
                      currentLayerSettingModel.layerRealationships.map(item => {
                        ListInfoMation.push({
                          label: "qddc",
                          value: item.contentChange,
                        });
                      })
                    }
                    if (currentLayerSettingModel.layerRealationshipId != 0) {
                      ListInfoMation.push({
                        label: "Năm điều chỉnh",
                        value: currentLayerSettingModel.year + "",
                      });
                    }
                    if (currentLayerSettingModel.layerRealationshipId != 0) {
                      ListInfoMation.push({
                        label: "Tên quy hoạch",
                        value: currentLayerSettingModel.contentChange,
                      });
                    }
                  }
                } 
                else {
                  const relatedLayer: any = this.state.relatedLayer?.filter(x => x.id === layerId)[0];
                  // let relatedLayer: any = this.state.relatedLayer;
                  const colsRaw: any = {};
                  const displayDetails: any = []
                  //console.log('relatedLayer_YYYYYYYYYYYYYYYYYYyyy',relatedLayer);
                  if (Array.isArray(relatedLayer.display_name.cols) && relatedLayer.display_name.cols.length > 0) {
                    relatedLayer.display_name.cols.map((col: any) => {
                      if (col.col != '__gid'
                        && col.col != '____gid'
                        && col.col != 'id'
                        && col.col != 'ten_loai_dat') {
                        displayDetails.push(col.col);
                        colsRaw[col.col] = col.alias;
                      }
                    })
                  }
                  if (Array.isArray(displayDetails) && displayDetails.length > 0) {
                    displayDetails.map((columnName) => {
                      ListInfoMation.push({
                        label: colsRaw[columnName],
                        value: featuresProperies[columnName],
                      });
                    });
                  }
                  //console.log('colsRaw_XXXXXXXXXXXXXXXXXXXXXXXXXXx',colsRaw);
                }
              }
              // n.features.map(features => MergeData(features.properties))
              // console.log('featureCollection__xxx', featureCollection);
              setDataForTabInformation(featureInfo?.properties)

              /********************************************************************/

              this.props.SaveInfomationList(ListInfoMation);
              if (!this.state.isLoadedAllVectorSource) {
                let _isHaveHighlightVectorlayer = false;
                listLayer.forEach((_layer) => {
                  if ((_layer instanceof VectorLayer) && (_layer.getClassName() === `highlight-vectorlayer`)) {
                      _isHaveHighlightVectorlayer = true;
                    const _newVectorSource = new VectorSource({
                      features: new GeoJSON().readFeatures(_geojsonObject),
                    })
                    _layer.setSource(_newVectorSource)
                  }
                })
                console.log('_isHaveHighlightVectorlayer', _isHaveHighlightVectorlayer);
                if (!_isHaveHighlightVectorlayer) {
                  listLayer.push(
                    new VectorLayer({
                      className: `highlight-vectorlayer`,
                      source: new VectorSource({
                        features: new GeoJSON().readFeatures(_geojsonObject),
                      }),
                      zIndex: 999,
                      style: new Style({
                        stroke: new Stroke({
                          color: 'rgba(0,230,241,1)',
                          width: 2,
                        }),
                        fill: new Fill({
                          color: 'rgba(223,16, 188,0.5)',
                        }),
                      }),
                    })
                  )
                }
              }
            } else {
              this.functionClickShowPopUp(null);
              this.removeHiglightVectorLayer();
              layerIndex++;
              if (layerIndex < listTitleLayerVisible.length) {
                getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
              } else {
                this.props.SaveInfomationList([]);
              }
              this.functionClickShowPopUpInClickedCord(null, null);
            }
          },
        });
      }
    }

    planningInfomationSmallPopupElement?.click();
    if (buttonTabMapinfomation && mapInfomationContainer) {
      if (buttonTabMapinfomation.classList.contains("collapsed"))
        buttonTabMapinfomation.click();
      if (!mapInfomationContainer.getAttribute("open"))
        mapInfomationContainer.setAttribute("open", "");
    }
    listLayer.forEach((layer) => {
      if ((layer instanceof TileLayer) && (layer.getClassName() != 'ol-layer') && layer.getVisible()) {
        listTitleLayerVisible.push(layer);
      }
      if ((layer instanceof VectorImageLayer) && layer.getVisible()) listVectorImageLayerVisible.push(layer);
      if ((layer instanceof ImageLayer) && layer.getVisible()
        && layer.getClassName().includes("layer_boundaries")) listImageLayerVisible.push(layer);
    })
    listTitleLayerVisible.reverse();
    listVectorImageLayerVisible.reverse();
    listTitleLayerVisible.sort((a, b) => b.getZIndex() - a.getZIndex());
    if (listTitleLayerVisible.length > 0) {
      getFeaturesWithTitleLayer(listTitleLayerVisible[layerIndex])
    } else {
      this.props.SaveInfomationList([]);
    }

    listImageLayerVisible.reverse();
    listVectorImageLayerVisible.reverse();
    listImageLayerVisible.sort((a, b) => b.getZIndex() - a.getZIndex());
    if (listImageLayerVisible.length > 0) {
      getFeaturesWithTitleLayer(listImageLayerVisible[layerIndex])
    } else {
      this.props.SaveInfomationList([]);
    }

    console.log("getLayer", listLayer);
    console.log("getOverlays", mapObject.getOverlays());
    if(query) clearTimeout(query);
  };

  functionHightLightPolygon = (listPolygon: any) => {
    const mapObject: Map = this.state.map;
    const listLayer = mapObject.getLayers();
    let _isHaveHighlightVectorlayer = false;
    const feature: any[] = [];

    listPolygon.map((boundariesData: any) => {
      const format = new WKT();

      const featureObject = format.readFeature(boundariesData, {
        dataProjection: this.props.projection,
        featureProjection: this.props.projection,
      });
      console.log('fhkjghkjrl;akjdhfkjsdhfkjdsf',featureObject)
      feature.push(featureObject);
    });
    listLayer.forEach((_layer) => {
      if ((_layer instanceof VectorImageLayer) && (_layer.getClassName() === "filter-vectorlayer")) {
        _isHaveHighlightVectorlayer = true;
        const _newVectorSource = new VectorSource({
          features: feature,
        })
        _layer.setSource(_newVectorSource)
      }
    })
    if (!_isHaveHighlightVectorlayer) {
      listLayer.push(
        new VectorImageLayer({
          className: "filter-vectorlayer",
          source: new VectorSource({
            features: feature,
          }),
          zIndex: 9000,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(0,230,241,1)',
              width: 3,
            }),
            fill: new Fill({
              color: 'rgba(223,16, 188,0.5)',
            }),
          }),
        })
      )
    }

  }

  functionHandleDisplayGeneralPlanningMode = (OpenlayerMapObject: Map) => {
    const self = this;
    OpenlayerMapObject.on("pointermove", function (e) {
      if (selected !== null) {
        selected.setStyle(undefined);
        selected = null;
      }

      OpenlayerMapObject.forEachFeatureAtPixel(e.pixel, function (f: any) {
        const highlightStyle = new Style({
          fill: new Fill({
            color: "rgba(251,130,50,0.5)",
            //color: "rgba(251,28,28,1)",
          }),
          stroke: new Stroke({
            color: "rgba(251,28,28,1)",
            width: 1,
          }),
        });
        selected = f;
        f.setStyle(highlightStyle);
        return true;
      });
      if (e.dragging) {
        (document.getElementById(
          "tooltip-container-openlayer"
        ) as HTMLElement).style.opacity = "0";
      } else {
        self.functionHoverHightlightAndShowTooltip(
          self.state.map.getEventPixel(e.originalEvent)
        );
        self.functionHoverHightlightAndShowPopUp(
          self.state.map.getEventPixel(e.originalEvent)
        );
      }
    });

    OpenlayerMapObject.on("singleclick", function (e) {
      self.functionHandleClickLayerToDirectDetail(
        self.state.map.getEventPixel(e.originalEvent)
      );
    });
  };


  removeHiglightVectorLayer() {
    const _objectMap: Map = this.state.map;
    if (!_objectMap) return;
    const _layers = _objectMap.getLayers();
    let _indexDelete = -1;
    _layers.getArray().map((_layer, _index) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer")) {
        _indexDelete = _index;
        return;
      }
    })
    if (_indexDelete != -1) _layers.removeAt(_indexDelete)
  }

  removeHiglightVectorLayerChild() {
    const _objectMap: Map = this.state.map;
    if (!_objectMap) return;
    const _layers = _objectMap.getLayers();
    let _indexDelete = -1;
    _layers.getArray().map((_layer, _index) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === "highlight-vectorlayer-child")) {
        _indexDelete = _index;
        return;
      }
    })
    if (_indexDelete != -1) _layers.removeAt(_indexDelete)
  }

  clearFeatures() {
    this.state.map?.getLayers().getArray().map((item: any) => {
      if (item instanceof VectorImageLayer) {
        console.log("layer", item);
      }
    })
  }

  resetDrawnFilterDataAndRemoveInteraction = () => {
    const _mapObject: Map = this.state.map;
    if (DrawnFilterData.draw) _mapObject.removeInteraction(DrawnFilterData.draw);
    DrawnFilterData.draw = null;
    DrawnFilterData.source = null;
    DrawnFilterData.filterType = '';
  }

  createFilterDrawnVectorLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layersCollect = _mapObject.getLayers();
    let _needCreateVectorLayer = true;
    let _drawnFilterLayer: any = null;
    const _newSource = new VectorSource();

    this.resetDrawnFilterDataAndRemoveInteraction();

    DrawnFilterData.source = _newSource;
    _layersCollect.forEach((_layer) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === classNameVectorLayerFilterDrawn)) {
        _needCreateVectorLayer = false;
        _drawnFilterLayer = _layer;
      }
    })
    if (_needCreateVectorLayer) {
      const _vectorLayerDrawn = new VectorLayer({
        source: _newSource,
        className: classNameVectorLayerFilterDrawn,
        zIndex: 1000,
        style: Draw_Filter_Feature_Style,
      })
      _layersCollect.push(_vectorLayerDrawn);
    } else {
      _drawnFilterLayer.setSource(_newSource)
    }
  }

  deleteFilterDrawnVectorLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layersCollect = _mapObject.getLayers();
    let _indexDelete = -1;

    this.resetDrawnFilterDataAndRemoveInteraction();

    _layersCollect.forEach((_layer, _index) => {
      if ((_layer instanceof VectorLayer) && (_layer.getClassName() === classNameVectorLayerFilterDrawn)) {
        _indexDelete = _index;
      }
    })
    if (_indexDelete !== -1) _layersCollect.removeAt(_indexDelete);
  }

  filterDrawnVectorLayer = (drawnType: string) => {
    const _mapObject: Map = this.state.map;
    let _drawnType: any = drawnType;
    let _geometryFunction = null;
    this.createFilterDrawnVectorLayer()
    switch (drawnType) {
      case GeometryType.POINT:
      case GeometryType.LINE_STRING:
      case GeometryType.POLYGON:
      case GeometryType.CIRCLE: {
        _drawnType = drawnType
      } break;
      case "Box": {
        _drawnType = GeometryType.CIRCLE;
        _geometryFunction = createBox();
      } break;
    }
    switch (drawnType) {
      case GeometryType.POINT: DrawnFilterData.filterType = 'Point'; break
      case GeometryType.LINE_STRING: DrawnFilterData.filterType = 'LineString'; break
      case GeometryType.POLYGON: DrawnFilterData.filterType = 'Polygon'; break
      case GeometryType.CIRCLE: DrawnFilterData.filterType = 'Circle'; break
      case "Box": DrawnFilterData.filterType = 'Box'; break
    }
    if (DrawnFilterData.source) {
      let _optionDraw: any = {
        source: DrawnFilterData.source,
        type: _drawnType,
      }
      if (_geometryFunction) {
        _optionDraw.geometryFunction = _geometryFunction;
      }
      DrawnFilterData.draw = new Draw(_optionDraw)
      DrawnFilterData.draw.on("drawstart", (evt) => {
        DrawnFilterData.source?.clear()
      })
      if (drawnType === GeometryType.POINT) {
        DrawnFilterData.draw.on("drawend", (evt) => {
          const _pointGeoMetry: any = evt.feature.getGeometry();
          document.getElementById('longitudeInput')?.setAttribute('value', _pointGeoMetry.flatCoordinates[0])
          document.getElementById('latitudeInput')?.setAttribute('value', _pointGeoMetry.flatCoordinates[1])
        })
      }
      _mapObject.addInteraction(DrawnFilterData.draw)
    }
  }

  getCurrentDrawFeature = () => {
    let _feature: Feature | null = null
    if (DrawnFilterData.source) {
      const _featureList = DrawnFilterData.source.getFeatures();
      if (_featureList.length > 0) _feature = _featureList[0];
    }
    const _format = new WKT();
    if (_feature) {
      let _featureGeometry: any = _feature.getGeometry();
      let _pointRadius: any = 0;
      let _geomText = '';
      if (DrawnFilterData.filterType === 'Circle') {
        const _mapObject: Map = this.state.map;
        const _centerCircle = _featureGeometry.getCenter();
        _geomText = `POINT(${_centerCircle[0]} ${_centerCircle[1]})`;
        _pointRadius = _mapObject.getView().getProjection().getMetersPerUnit() * _featureGeometry.getRadius();
      } else {
        if (DrawnFilterData.filterType === 'Point') {
          let _radiusInput = document.getElementById("radiusInput")?.getAttribute("value");
          if (typeof Number(_radiusInput) === 'number') {
            _pointRadius = Number(_radiusInput) || 0
          } else {
            ShowNotification(
              "Dữ liệu bạn nhập vào không đúng định dạng",
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
            _pointRadius = 0;
          }
        }
        _geomText = _format.writeGeometry(_featureGeometry);
      }
      return {
        coordinate: _geomText,
        r: _pointRadius,
        type: DrawnFilterData.filterType,
      }
    }
    return null
  }


  deleteHightlightFilterLayer = () => {
    const _mapObject: Map = this.state.map;
    const _layers = _mapObject.getLayers();
    console.log("filter-vectorlayer", _layers);
    let _removeIndex = -1;
    _layers.forEach((_layer, _index) => {
      if ((_layer instanceof VectorImageLayer) && _layer.getClassName() === 'filter-vectorlayer') {
        _removeIndex = _index;
      }
      if (_removeIndex !== -1) {
        _layers.removeAt(_removeIndex)
      }
    })

  }

  addLayerManually = (tableName: string, isAddedLayer: boolean, zIndex: number) => {
    const layerImage: any = new ImageLayer({
      visible: true,
      zIndex: zIndex,
      minZoom: 0,
      maxZoom: 20,
      source: new ImageWMS({
        url: config.DoMainNameGeoServerWMS,
        params: {
          LAYERS: `${config.DataStore}:${`${tableName}`}`,
          LayerId: 100,
          FORMAT: "image/png",
          VERSION: "1.1.0",
        },
        crossOrigin: 'anonymous'
      }),
      className: `added_manually_image_layer_${tableName}`,
    });
    this.state.map?.addLayer(layerImage);
    
    // console.log("getLayers",this.state.map,this.state.map?.getLayers());
    console.log("added result ", layerImage);
    
  }

  componentDidMount() {
    const defaultBaseMapUrl =
      this.props.defaultBaseMapUrl ||
      "https://mt0.google.com/vt/lyrs=y&hl=vi&x={x}&y={y}&z={z}";
    const defaultCenter = this.props.defaultCenter || config.defaultMapCenter;
    const defaultZoom = this.props.defaultZoom || 9;
    const defaultMaxZoom = this.props.maxZoom || 24;
    const defaultMinZoom = this.props.minZoom || 1;
    const defaultListLayer = this.props.listLayer || [];
    console.log("Checkkkkkkk",defaultListLayer);
    
    let ListLayerDefault = 0;
    const _updateLoadLayerState = () => {
      ListLayerDefault--;
      if (ListLayerDefault === 0) {
        this.setState({
          isLoadedAllVectorSource: true,
        })
        this.removeHiglightVectorLayer();
      }
    }
    const defaultExtend = this.props.extent || [
      config.defaultLeftCornerLng,
      config.defaultLeftCornerLat,
      config.defaultRightCornerLng,
      config.defaultRightCornerLat,
    ];
    const DefaultLayers: Array<
      TileLayer | ImageLayer | VectorLayer | VectorImageLayer
    > = [
        new TileLayer({
          source: new XYZ({
            url: defaultBaseMapUrl,
            maxZoom: this.props.maxZoom,
            crossOrigin: "anonymous",
          }),
        }),
      ];
    this.setState({
      listLayerSettingModel: defaultListLayer,
      isLoadedAllVectorSource: !defaultListLayer.length,
    })
    ListLayerDefault = defaultListLayer.length;
    console.log("defaultListLayerdefaultListLayerdefaultListLayer",defaultListLayer);
    
    defaultListLayer.map((layerData, index) => {
      const layerImage: any = new TileLayer({
        visible: layerData.is_check,
        zIndex: layerData.z_index + 100,
        minZoom: layerData.min_zoom,
        maxZoom: layerData.max_zoom,
        source: new TileWMS({
          // url : config.gwcUrl,
          url : layerData.wms,
          params: {
            LAYERS: `${config.DataStore}:${layerData.table}`,
            LayerId: layerData.id,
            FORMAT: "image/png",
            VERSION: "1.1.0",
          },
          crossOrigin: 'anonymous'
        }),
        className: TileLayerClassName(layerData.id),
      });

      if(layerData.geoTiff) {
        const layerTiff: any = new TileLayer({
          visible: layerData.is_check,
          zIndex: layerData.z_index,
          minZoom: layerData.min_zoom,
          maxZoom: layerData.max_zoom,
          source: new TileWMS({
            // url : config.gwcUrl,
            url : layerData.wms,
            params: {
              LAYERS: `${config.DataStore}:${layerData.geoTiff}`,
              LayerId: layerData.id,
              FORMAT: "image/png",
              VERSION: "1.1.0",
            },
            crossOrigin: 'anonymous'
          }),
          className: `layer-tiff-${layerData.geoTiff}`,
        });
        DefaultLayers.push(layerTiff);
      }

      DefaultLayers.push(layerImage);

      if (index === 0) {
        this.setState({
          currentLayer: layerImage,
          currentLayerSettingModel: layerData,
          primaryIdLayer: layerData.id
        });
      }
    });
    this.setHeightViewport();
    const IdIntervalSettingMap = setInterval(() => {
      if (this.state.mapContainerStyle) {
        var mousePositionControl = new MousePosition({
          coordinateFormat: createStringXY(4),
          projection: "EPSG:4326",
          className: "custom-mouse-position",
          target: document.getElementById("mouse-position") as HTMLElement,
          undefinedHTML: "&nbsp;",
        });
        var mapScale = new ScaleLine({
          className: "custom-map-scale",
          target: document.getElementById("map-scale") as HTMLElement,
          units: "metric",
          bar: true,
          steps: parseInt("4", 10),
          text: true,
          minWidth: 140,
        });
        var OpenlayerMapObject = new Map({
          controls: defaultControls().extend([mousePositionControl,mapScale]),
          target: "map-container-id",
          layers: DefaultLayers,
          view: new View({
            projection: this.props.projection,
            center: defaultCenter,
            zoom: defaultZoom,
            minZoom: defaultMinZoom,
            maxZoom: defaultMaxZoom,
            extent: defaultExtend,
          }),
        });
        
        OpenlayerMapObject.addOverlay(
          new Overlay({
            position: [0, 0],
            element: document.getElementById("null") as HTMLLinkElement,
          })
        );

        this.props.SaveMapBase(OpenlayerMapObject);

        if (this.props.isGeneralPlanning) {
          this.functionHandleDisplayGeneralPlanningMode(OpenlayerMapObject);
        }

        OpenlayerMapObject.on('moveend',()=>{
          var newZoom = OpenlayerMapObject.getView().getZoom();
          // OpenlayerMapObject.getView().getCenter();
          this.setState({
            currentZoom: newZoom
          })
        })

        this.setState(
          {
            map: OpenlayerMapObject,
            relatedLayer: []
          },
          () => {
              this.handleOnOffViewInfomation(true);
              console.log("getLayers",OpenlayerMapObject.getLayers());
              
          }
        );
        this.props.setIsDoneConstructMap && this.props.setIsDoneConstructMap(true);

        clearInterval(IdIntervalSettingMap);
      }
    }, 500);
    //-- update handle function
    this.props.CreateOpenlayerController({
      handleOnOffViewInfomation: this.handleOnOffViewInfomation,
      toggleDisplayVectorLayer: this.toggleDisplayVectorLayer,
      toggleDisplayLayer: this.toggleDisplayLayer,
      changeBaseMap: this.changeBaseMap,
      handleSelectedOne: this.handleSelectOne,
      removeInteractionOnMap: this.removeInteractionOnMap,
      functionTurnOffFilterMode: this.functionTurnOffFilterMode,
      functionClickViewInfomationListener: this.functionClickViewInfomationListener,
      functionHightLightPolygon: this.functionHightLightPolygon,
      filterDrawnVectorLayer: this.filterDrawnVectorLayer,
      deleteFilterDrawnVectorLayer: this.deleteFilterDrawnVectorLayer,
      getCurrentDrawFeature: this.getCurrentDrawFeature,
      addLayerManually : this.addLayerManually,
    });
    
  }
  animatePromise = (zoomVal: any, lat: any, lng: any) => {
    const mapObject = this.state.map;
    return new Promise((resolve) => {
      mapObject.getView().animate({
        zoom: zoomVal,
        duration: this.state.currentZoom > 16.5 ? animateDurationZoomedIn : animateDurationZoomedOut,
        center: [lat ? parseFloat(lat) : 0, lng ? parseFloat(lng) : 0],
      });

      setTimeout(resolve, this.state.currentZoom > 16.5 ? animateDurationZoomedIn : animateDurationZoomedOut);
    });
  }
  async componentDidUpdate(prevProps:any, prevState:any) {
    console.log('this.props.projectcoordinate',this.props.projectcoordinate)
    console.log('this.prevProps.projectcoordinate',prevProps.projectcoordinate)
    if (this.props.projectcoordinate && this.props.projectcoordinate !== prevProps.projectcoordinate) {
      const mapObject: Map = this.state.map;

      await this.animatePromise(18, this.props.projectcoordinate.coordinate[0], this.props.projectcoordinate.coordinate[1]);
      this.functionClickViewInfomationListener(this.props.projectcoordinate)
    }
    if (this.state.currentZoom !== prevState.currentZoom) {



      this.functionClickShowPopUpInClickedCord(null,null);
    }
  }


  render() {
    return (
      <div
        className="openlayer-map-view-container"
        style={this.state.mapContainerStyle}
      >
        <div>
          <button
            hidden
            id="print"
            type="button"
            className="btn btn-primary position-absolute map-tools-container"
            data-toggle="modal"
            data-target="#exampleModalLong"
          >
            Launch demo modal
          </button>
          <div
            className="modal"
            id="exampleModalLong"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div
                className="modal-content"
                style={{ width: "1376px", height: "695px", left: "-385px" }}
              >
   
                <div
                  className="modal-body"
                  style={{ padding: "0px", height: "90%" }}
                >
                  <div className="mapname" id="mapname" style={{ textAlign: "center", padding: "10px", fontSize: "20px", fontWeight: "bold" }}>{this.props.planningModel && this.props.planningModel.name}</div>
                  <div
                    style={{ width: "100%", height: "100%" }}
                    className="page"
                    id="page"
                  >
                    <div className="left-print" id="left-print">
                      <h3>Thông tin</h3>
                      {this.props.listMapInfomations && this.props.listMapInfomations.length > 0 && this.props.listMapInfomations.map((item: any, index: number) =>
                        <p>{item.label}: {item.value}</p>
                      )}
                    </div>
                    <img
                      id="imagemap"
                      style={{ width: "100%", height: "100%" }}
                      alt="Image Map"
                    />
                    <p style={{ marginLeft: "25%", position: "relative", top: "-25px", backgroundColor: "white" }}><span style={{ color: "red" }}>* Chú ý: </span><span>Kết quả tra cứu chỉ có tính chất tham khảo</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MapContainer />
        <div id="mouse-position"></div>
        <div id="map-scale"></div>
        <div id="tooltip-container-openlayer"></div>
        <div id="click-show-popup-container-openlayer"></div>
        <div id="popup-container-openlayer"></div>
        {
          this.props.isGeneralPlanning && <Popover id="show-popup-clicked-cord" placement="right" aria-label="show-popup-clicked-cord"/>
        }
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  dataFilter: state.mapData.dataFilter,
  featuresSelected: state.openlayer.featuresSelected,
  listMapInfomations: state.mapData.infomations,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      CreateOpenlayerController: OpenlayerStore.CreateOpenlayerController,
      SaveOpenlayerFeaturesSelected: OpenlayerStore.SaveOpenlayerFeaturesSelected,
      SaveInfomationList: MapDataStore.SaveInfomationList,
      SaveChildInfomationList: MapDataStore.SaveChildInfomationList,
      SaveInfomationForTable: MapDataStore.SaveInformationForTable,
      SaveHeaderForTable: MapDataStore.SaveHeaderForTable,
      SaveMapBase: MapDataStore.SaveMapBase,
      SetDisplayInfomationPopup: MapToolsPanelsStore.SetDisplayInfomationPopup,
      ShowLoading: LoadingAction.ShowLoading,
      HiddenLoading: LoadingAction.HiddenLoading,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(OpenlayersMapView));



export const ApiUrl = {
  ContactToAdmin: 'api/cms/HomePage/ContactToAdmin',
  SlideShow: 'api/cms/HomePage/GetListPostHomePage',
  GetMapPlanningById: 'api/cms/Planning/get-map-by-planning-id',
  GetAllBoundariesOfAllPlanning: 'api/cms/Planning/get-all-planning-boundaries',
  GetLookupNewsCategories: 'api/admin/News_Category/GetLookup',
  GetListNewsByCategory: '/api/cms/News/GetListByCat',
  GetListHotNews: '/api/cms/News/GetListHot',
  GetDetailNews: '/api/cms/News/GetDetail',
  GetListFloods: '/api/admin/Floods/GetListAll',
  GetListLandSlide: '/api/admin/LandSlides/GetListAll',
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { faMap } from "@fortawesome/free-regular-svg-icons";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import axios from "axios";
import DatePicker from "react-datepicker";
import ViLanguages from "../../../languages/vi";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import * as FilterStore from "../../../redux/store/filter/filter.store";
import * as MapToolDataStore from "../../../redux/store/map-tool/map-tool.store";
import LayerCategoryModels from "../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import InfoPlanning from "./info/planning";
import SearchTab from "./info/search";
import "./left-menu.scss";
import moment from "moment";
type LeftMenuViewProps = {
  planningId: any;
  listMapInfomations: any;
  openLayerController: any;
  headerTable: any;
  dataToggleLayer: any;
  isLeftNavbarHide: boolean;
  toggleStateIsLeftNavbarHide: () => void;
  UpdateDataToggleLayer: (layer: any) => void;
  openFilterInfomationPopup: () => void;
  setProjectcoordinate: any;
};

const LanguageCollects = ViLanguages;

const LeftMenuView: React.FC<LeftMenuViewProps> = (props) => {
  const { setProjectcoordinate } = props;
  const [headerTable, setHeaderTable] = useState<any>(null);
  const [surveyerDateFlood, setSurveyerDateFlood] = useState<any>(null);
  const [surveyerDateLand, setSurveyerDateLand] = useState<any>(null);
  const [showNote, setShowNote] = useState<boolean>(false);
  const [showLandSlides, setShowLandSlides] = useState<boolean>(false);
  const [noteData, setNoteData] = useState<any>(null);
  const [floodData, setFloodData] = useState<any[]>([]);
  const [landslides, setLandslides] = useState<any[]>([]);
  const [searchParamsFlood, setSearchParamsFlood] = useState({
    placename: "",
    surveyer: "",
    surveyerDate: null,
  });
  const [currentPageFlood, setCurrentPageFlood] = useState<number>(1);
  const [totalPagesFlood, setTotalPagesFlood] = useState<number>(1);
  const [searchParams, setSearchParams] = useState({
    placename: "",
    surveyer: "",
    surveyerDate: null,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    setHeaderTable(props.headerTable);
  }, [props.headerTable]);

  useEffect(() => {
    if (isMobile) {
      handleClickToggleLeftNavBar();
    }
  }, []);
  useEffect(() => {
    fetchLandSlidesData();
  }, [searchParams, currentPage]);
  useEffect(() => {
    fetchFloodData();
    fetchLandSlidesData();
  }, [searchParamsFlood, currentPageFlood]);
  useEffect(() => {
    setCurrentPageFlood(1);
    fetchFloodData();
  }, [showNote]);
  useEffect(() => {
    setCurrentPage(1);

    fetchLandSlidesData();
  }, [showLandSlides]);
  useEffect(() => {
    setCurrentPage(1);
    fetchFloodData();
  }, [surveyerDateFlood]);
  useEffect(() => {
    setCurrentPage(1);
    fetchLandSlidesData();
  }, [surveyerDateLand]);
  const fetchFloodData = async () => {
    return new Promise((resolve, reject) => {
      FilterStore.GetListFloods({
        pageIndex: currentPageFlood,
        pageSize: 10,
        placename: searchParamsFlood.placename,
        surveyer: searchParamsFlood.surveyer,
        surveyerDate:
          (surveyerDateFlood &&
            moment(surveyerDateFlood).format("YYYY-MM-DD")) ||
          null,
      }).then(
        (res) => {
          setFloodData(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setTotalPagesFlood(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  const fetchLandSlidesData = async () => {
    return new Promise((resolve, reject) => {
      FilterStore.GetListLandSlide({
        pageIndex: currentPage,
        pageSize: 10,
        placename: searchParams.placename,
        surveyer: searchParams.surveyer,
        surveyerDate:
          (surveyerDateLand && moment(surveyerDateLand).format("YYYY-MM-DD")) ||
          null,
      }).then(
        (res) => {
          setLandslides(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setTotalPages(
            res && res.content && res.content.totalItemCount
              ? res.content.totalItemCount
              : 0
          );
          setTotalPages(
            res && res.content && res.content.pageCount
              ? res.content.pageCount
              : 0
          );
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    setCurrentPage(1);
  };
const convertCoordinate = (data: [number, number]) => {
    let coorObject = { coordinate: data };
    console.log('coorObject', coorObject);
    setShowNote(false)
    setShowLandSlides(false)
    setProjectcoordinate(coorObject);
};

  const handleSearchChangeFlood = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParamsFlood({
      ...searchParamsFlood,
      [e.target.name]: e.target.value,
    });
    setCurrentPageFlood(1);
  };
  const handleClickToggleLeftNavBar = () => {
    props.toggleStateIsLeftNavbarHide();
    const navbar = document.getElementsByClassName(
      "left-custom-navbar"
    )[0] as HTMLElement;
    if (props.isLeftNavbarHide) {
      navbar.classList.remove("hide-left-navbar");
    } else {
      navbar.classList.add("hide-left-navbar");
    }
  };

  const handleClickIconLeftNavbar = (id: string) => {
    handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLButtonElement;
    if (buttonElement?.classList.contains("collapsed")) buttonElement.click();
  };

  const handleToggleDisplayLayer = (layerTarget: any) => {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  };

  return (
    <>
      <div className="left-custom-navbar position-absolute">
        <div className={`h-100 position-relative custom-container-left-menu`}>
          <div className="navbar-custom" id="accordion">
            <div className="header-navbar-custom">
              <button
                className="btn"
                data-toggle="collapse"
                data-target="#mapLayersTab"
                aria-expanded="true"
                aria-controls="mapLayersTab"
              >
                {LanguageCollects.planningMapView_map}
              </button>
              <button
                id="ButtonControlMapInfomationTab"
                className="btn collapsed"
                data-toggle="collapse"
                data-target="#mapInfomationTab"
                aria-expanded="false"
                aria-controls="mapInfomationTab"
              >
                {LanguageCollects.planningMapView_infomation}
              </button>
              <button
                id="ButtonControlMapSearchTab"
                className="btn collapsed"
                data-toggle="collapse"
                data-target="#mapSearchTab"
                aria-expanded="false"
                aria-controls="mapSearchTab"
              >
                Tìm kiếm
              </button>
              <button
                className="btn btn-hide-left-navbar position-absolute"
                onClick={handleClickToggleLeftNavBar}
              >
                <FontAwesomeIcon
                  icon={props.isLeftNavbarHide ? faAngleRight : faAngleLeft}
                  color="white"
                  size="lg"
                />
              </button>
            </div>

            <div
              hidden={props.isLeftNavbarHide}
              style={{ overflowY: "scroll" }}
            >
              <div
                id="mapLayersTab"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                {props.dataToggleLayer.map((data: any, index: number) => (
                  <RenderMapChild
                    key={index}
                    data={data}
                    toggleLayerAction={handleToggleDisplayLayer}
                    updateDataToggleLayer={props.UpdateDataToggleLayer}
                    // toggleLeftNavBarAction={handleClickToggleLeftNavBar}
                    setShowNote={setShowNote}
                    setShowLandSlide={setShowLandSlides}
                    setNoteData={setNoteData}
                    openFilterInfomationPopup={props.openFilterInfomationPopup}
                  />
                ))}
              </div>

              <div
                id="mapInfomationTab"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <InfoPlanning listMapInfomations={props.listMapInfomations} />
              </div>
              <div
                id="mapSearchTab"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <SearchTab
                  setShowNote={setShowNote}
                  setShowLandSlides={setShowLandSlides}
                />
              </div>
            </div>

            <div
              className="header-navbar-custom-icons-style text-center position-absolute"
              hidden={!props.isLeftNavbarHide}
            >
              <button
                title={LanguageCollects.planningMapView_map}
                onClick={() => handleClickIconLeftNavbar("mapLayersTab")}
              >
                <FontAwesomeIcon icon={faMap} color="#00923f" size="lg" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Flood List */}
      <Modal
        show={showNote}
        onHide={() => setShowNote(false)}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Danh sách các điểm ngập lụt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-2 col-lg-2 form-group">
              <label htmlFor="consultingUnit">Địa điểm</label>
              <input
                type="text"
                name="placename"
                className="form-control"
                value={searchParamsFlood.placename}
                onChange={handleSearchChangeFlood}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-2 form-group">
              <label htmlFor="consultingUnit">Người thăm dò </label>
              <input
                type="text"
                name="surveyer"
                className="form-control"
                value={searchParamsFlood.surveyer}
                onChange={handleSearchChangeFlood}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-4 form-group">
              <label htmlFor="startDate">Ngày thăm dò</label>
              <DatePicker
                selected={surveyerDateFlood}
                onChange={(date) => setSurveyerDateFlood(date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholderText="Chọn ngày dd/MM/yyyy"
                isClearable={true}
              />
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Creation Date</th>
                <th>Place Name</th>
                <th>Flood House</th>
                <th>Flood Road</th>
                <th>Survey Date</th>
                <th>Surveyer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {floodData.map((flood, index) => (
                <tr key={index}>
                  <td>
                      {flood.creationDa ? moment(flood.creationDa).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td>{flood.placeName}</td>
                  <td>{flood.floodHouse ?? "N/A"}</td>
                  <td>{flood.floodRoad ?? "N/A"}</td>
                  <td>{flood.surveyDate ? moment(flood.surveyDate).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td>{flood.surveyer}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => convertCoordinate([flood.lat, flood.lng])}
                    >
                      Xem 
                    </button>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button
              disabled={currentPageFlood <= 1}
              onClick={() => setCurrentPageFlood(currentPageFlood - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPageFlood} of {totalPagesFlood}
            </span>
            <button
              disabled={currentPageFlood >= totalPagesFlood}
              onClick={() => setCurrentPageFlood(currentPageFlood + 1)}
            >
              Next
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowNote(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modal for LandSlide List */}
      <Modal
        show={showLandSlides}
        onHide={() => setShowLandSlides(false)}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Danh sách các điểm sạt lở</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-12 col-md-2 col-lg-2 form-group">
              <label htmlFor="consultingUnit">Địa điểm</label>
              <input
                type="text"
                name="placename"
                className="form-control"
                value={searchParamsFlood.placename}
                onChange={handleSearchChangeFlood}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-2 form-group">
              <label htmlFor="consultingUnit">Người thăm dò</label>
              <input
                type="text"
                name="surveyer"
                className="form-control"
                value={searchParamsFlood.surveyer}
                onChange={handleSearchChangeFlood}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-4 form-group">
              <label htmlFor="startDate">Ngày thăm dò</label>
              <DatePicker
                selected={surveyerDateLand}
                onChange={(date) => setSurveyerDateLand(date)}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholderText="Chọn ngày dd/MM/yyyy"
                isClearable={true}
              />
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Creation Date</th>
                <th>Place Name</th>
                <th>Severity</th>
                <th>Cause</th>
                <th>Survey Date</th>
                <th>Surveyer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {landslides.map((flood, index) => (
                <tr key={index}>
                  <td>
                   {flood.creationDa ? moment(flood.creationDa).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td>{flood.placeName}</td>
                  <td>{flood.severity ?? "N/A"}</td>
                  <td>{flood.cause ?? "N/A"}</td>
                  <td>
                  {flood.surveyDate ? moment(flood.surveyDate).format("DD-MM-YYYY") : "N/A"}
                  </td>
                  <td>{flood.surveyer}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => convertCoordinate([flood.lat, flood.lng])}
                    >
                      Xem 
                    </button>
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <button
              disabled={currentPage <= 1}
              onClick={() => setCurrentPage(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              disabled={currentPage >= totalPages}
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setShowLandSlides(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type RenderMapChildProps = {
  data: any;
  toggleLayerAction: (layer: any) => void;
  updateDataToggleLayer: (layer: any) => void;
  setShowNote: (value: boolean) => void;
  setShowLandSlide: (value: boolean) => void;
  setNoteData: (data: any) => void;
  openFilterInfomationPopup: () => void;
};

const RenderMapChild: React.FC<RenderMapChildProps> = (props) => {
  const {
    data,
    toggleLayerAction,
    updateDataToggleLayer,
    setShowNote,
    setNoteData,
    setShowLandSlide,
    openFilterInfomationPopup,
  } = props;
  const [layerSetting, setLayerSetting] = useState<any[]>(data.layer_settings);

  const handleClickCheckbox = (LayerIndex: number, newValue: boolean) => {
    const updatedLayers = [...layerSetting];
    updatedLayers[LayerIndex].is_check = newValue;
    setLayerSetting(updatedLayers);
    toggleLayerAction(updatedLayers[LayerIndex]);
    updateDataToggleLayer(updatedLayers[LayerIndex]);
  };

  const handleShowNote = (item: any) => {
    setShowNote(true);
    setShowLandSlide(false);

    openFilterInfomationPopup();
  };
  const handleShowLandSlides = (item: any) => {
    setShowNote(false);
    setShowLandSlide(true);

    openFilterInfomationPopup();
  };
  return (
    <div className="render-map-child-container pt-2 pl-2 pr-2">
      <div className="select-container">
        {layerSetting.map((item, index) => (
          <div key={index} className="d-block">
            <label>
              <input
                type="checkbox"
                checked={item.is_check}
                onChange={() => {}}
                onClick={() => handleClickCheckbox(index, !item.is_check)}
                className="mr-1"
              />
              {item.name}&ensp;
            </label>

            {/* <span
              onClick={() => handleShowNote(item)}
              style={{ cursor: "pointer" }}
            >
              {(item.table === 'hueflood' || item.table === 'landslides') && (
                <FontAwesomeIcon icon={faFilter} color="#00786E" />
              )}
            </span> */}
            {(item.table === "hueflood" || item.table === "landslides") &&
              (item.table === "hueflood" ? (
                <span
                  onClick={() => handleShowNote(item)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faFilter} color="#00786E" />
                </span>
              ) : (
                <span
                  onClick={() => handleShowLandSlides(item)}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faFilter} color="#00786E" />
                </span>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  listMapInfomations: state.mapData.infomations,
  openLayerController: state.openlayer.openLayerController,
  headerTable: state.mapData.headerTable,
  dataToggleLayer: state.mapToolData.dataToggleLayer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetListPlanningRelationByPlanningId:
        MapDataStore.GetListPlanningRelationByPlanningId,
      UpdateDataToggleLayer: MapToolDataStore.UpdateDataToggleLayer,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuView);

const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
	//DOMAIN SERVER MỚI
	development: {
		// api: "https://hueflood-api.tqtrang67.website/",
		api: "http://localhost:5002/",
		domainAdminSide: "http://localhost:3001",
		domainUserSide: "http://localhost:3000",
		domainName: "",
		doMainNameGeoServer: "https://localhost:8080/geoserver/wfs",
		doMainNameGeoServerWMS: "https://localhost:8080/geoserver/hue/wms/",
		datastore: "hue",
		gwcUrl: "https://localhost:8080/geoserver/gwc/service/wms/",
	},
	production: {
		api: "https://hueflood-api.tqtrang67.website/",
		domainAdminSide: "https://hueflood-admin.tqtrang67.website",
		domainUserSide: "https://hueflood.tqtrang67.website",
		domainName: "tqtrang67.website",
		doMainNameGeoServer: "https://geo3.cgis.asia/geoserver/wfs",
		doMainNameGeoServerWMS: "https://geo3.cgis.asia/geoserver/hue/wms/",
		datastore: "hue",
		gwcUrl: "https://geo3.cgis.asia/geoserver/gwc/service/wms/",
	},
};

module.exports = apiEnvironment[env];
